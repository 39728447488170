import styles from "./styles.module.css";


export const Container = ({
  sidebar,
  body
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.layout}>
        <div className={styles.sidebar}>
          {sidebar}
        </div>
        <div className={styles.mainContent}>
          {body}
        </div>
      </div>
    </div>
  );
};

export default Container;
