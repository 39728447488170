import SidebarHeading from "../sidebar-heading/sidebar-heading";

import { expertise } from "../../data/data";

import styles from "./styles.module.css";


export const Expertise = () => {
  return (
    <div className={styles.expertise}>
      <SidebarHeading>{expertise.heading}</SidebarHeading>

      <ul className={styles.buzzwordList}>
        {expertise.entries.map((entry, index) => {
          return (
            <li key={`expertise-entry-${index}`}>{entry}</li>
          );
        })}
      </ul>
    </div>
  );
};

export default Expertise;
