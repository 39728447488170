import styles from "./styles.module.css";
import SidebarHeading from "../sidebar-heading/sidebar-heading";
import MainBodyEntry from "../main-body-entry/main-body-entry";

export const MainBodySection = ({
  heading,
  entries
}) => {
  return (
    <section className={styles.mainBodySection}>
      <div className={styles.heading}>
        <SidebarHeading>{heading}</SidebarHeading>
        <div className={styles.horizontalRule}>
          <hr />
        </div>
      </div>

      <ul className={styles.entryList}>
        {entries.map((entry, index) => {
          return (
            <li key={`main-body-entry-${index}`}>
              <MainBodyEntry {...entry} first={index === 0} />
            </li>
          )
        })}
      </ul>


    </section>
  );
};

export default MainBodySection;
