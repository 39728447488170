export const banner = {
  heading: "Jon Skeen",
  subheading: "Expert Web Developer"
}

export const profile = {
  heading: "Profile",
  copy: "I am a front end leader with over 15 years of professional experience developing web applications of all sizes.\n" +
      "I am currently focused on front end development, but have a full stack background with the accompanying expertise needed to gracefully connect the back and front ends.\n" +
    "I have led teams to success on large and small projects. I have a knack for dynamic programming and creating highly reusable components. I also enjoy mentoring junior developers and knowledge transfer in general.\n" +
    "I provide value and expertise across the full life cycle of a project. I can make initial tech selections, establish project architecture and coding standards, implement designs as high-fidelity components, and write documentation for future developer support and client handoff."
};

export const contact = {
  heading: "Contacts",
  methods: [
    {
      icon: "mobileScreenSolid",
      label: "(770) - 856 - 6795"
    },
    {
      icon: "envelope",
      label: "jonskeen@gmail.com"
    }
  ]
};


export const experienceP1 = {
  heading: "Experience",
  entries: [
    {
      heading: "Instrument",
      subheading: "Freelance Sr. Front End Developer",
      description: "Contributing code as part of a medium size team working on the rebuild of a major brand initiative. Established front end tooling, initial work breakdown from designs into actionable tickets, and reviewing code." +
        "Collaborating with back end and design teams to implement the design with high fidelity.",
      daterange: {
        start: 2023,
        end: "Now"
      }
    },
    {
      heading: "Savvy Coders",
      subheading: "Full Stack Instructor",
      description: "Leading full stack development lectures and contributing to course curriculum in a bootcamp setting. Working with cohorts of 8 - 12 students to provide the skills needed to begin a development career.",
      daterange: {
        start: 2023,
        end: "Now"
      }
    },
    {
      heading: "BuiltByBuilt.com",
      subheading: "Freelance Developer - Project Lead",
      description: "Building the primary landing page for the design agency Built using Sanity.io and Next.js. Responsible for the entire development process, from component breakdown to final deployment. Tasks include writing tickets and developing roadmaps; building front end components; customizing the CMS; establishing CI/CD pipelines; animating page elements with GSAP and CSS; authoring documentation; composing SEO micro data; setting up analytics; and finally, shipping the final product.",
      daterange: {
        start: 2023,
        end: "Now"
      }
    },
    {
      heading: "Top100GolfCourses.com",
      subheading: "Freelance Developer",
      description: "Worked in a support role to build reusable React components, implement new features, and squash bugs. This was a highly independent role where I was given a set of tasks and trusted to provide a timely delivery.",
      daterange: {
        start: 2022,
        end: 2023
      }
    },
  ]
};


export const experienceP2 = {
  heading: "More Experience",
  entries: [
    {
      heading: "Instrument",
      subheading: "Technical Lead",
      description: "BlackSpace.org Front End Technical Lead\n" +
        "\n" +
        "Led a small team of developers to build an elaborate custom WordPress template within an abbreviated timeline. Delivered custom CMS components and layouts for use within the WP admin view which populate custom components on the front end. Worked closely with the design team to break down design specs into workable developer tickets and to implement designs at a high level of fidelity." +
        "\n Coding contributions include general structure, templating system, animations using dynamic content, and many components on the admin and public interfaces. This project has since been used as a positive case study within Instrument." +
        "\nSalesForce+ Front End Technical Lead\n" +
          "Led a team of front end developers to build a streaming platform hosting live and video on demand content, which is used as a business critical marketing effort. Coordinated with multiple teams consisting of in-house and SalesForce partner developers to build and deliver reusable components and pages with high-fidelity design implementation. Worked with various client stakeholders to scope and coordinate releases in a complex ecosystem." +
        "\nResponsible for creating developer tickets; providing thorough code reviews; tracking and managing code releases; mentoring junior developers; providing code solutions and gathering requirements to unblock developers; establishing coding standards and best practices; developer guides and documentation; establishing a test framework and methodology; and overall code quality and on-time delivery of a very high visibility product. Work done within SalesForce’s Lightning Web Components framework.",
      daterange: {
        start: 2021,
        end: 2022
      }
    },
    {
      heading: "Cayuse, LLC",
      subheading: "Sr. Front End Developer",
      description: "Led greenfield effort to rebuild and expand a legacy app in React. Customized Webpack and Jest configurations and established coding standards and best practices used across an enterprise suite. Worked with UI/UX to create a Bit.dev component library for reuse across the suite. Presented Bit.dev to leadership and led training sessions. Oversaw an off-shore development team and mentored junior developers. Received \"Impact Award\" for these contributions.",
      daterange: {
        start: 2020,
        end: 2021
      }
    },
  ]
};

export const experienceP3 = {
  heading: "Even More Experience",
  entries: [
    {
      heading: "AKQA",
      subheading: "Sr. Front End Developer",
      description: "Led development of a CMS front and back end for TheTradeDesk.com using Next.js, React, and Craft. Evaluated and recommended tools, processes, and workflows; managed client demos; and guided teammates throughout the project implementation\n" +
        "Oversaw front end development for NeymarJr.com. Responsible for recommending React frameworks and customizing Webpack configuration; integrating with Contentful GraphQL API; implementing localization system; and integrating with MailChimp.\n" +
        "Helped develop Palms.com and Stations Casinos customer portal. Integrated with AEM to build account and booking pages. Built components capable of supporting multiple palettes and designs required by the various casinos.",
      daterange: {
        start: 2018,
        end: 2020
      }
    },
    {
      heading: "Adpearance",
      subheading: "Full Stack Developer",
      description: "Worked directly with Digital Advertising team to design and implement tools to facilitate management of digital ad campaigns on Google AdWords, Facebook, and Bing.  Responsible for finding and implementing solutions for team needs. Overhauled FourAds platform by reimplementing the front end in Angular and adding the Doctrine ORM to the back end, which greatly increased development velocity. Improved page performance by adding Redis caching and a multi-threaded pipeline for long running scripts. Eased deployments by creating a Gulp release script with status update email alerts. Automated several tasks to reduce demands on team size.",
      daterange: {
        start: 2015,
        end: 2018
      }
    },
    {
      heading: "Symplicity Corporation",
      subheading: "Full Stack Developer",
      description: "Led full stack development of several cross-product features including form, list, and report customization engines used by thousands of government and higher ed. clients. Helped develop company’s new flagship product as an AngularJS app. Contributed to company ORM framework and cross-product code base. Mentored several junior developers and led “JavaScript 101” workshops.",
      daterange: {
        start: 2008,
        end: 2015
      }
    }
  ]
};

export const education = {
  heading: "Education",
  entries: [
    {
      heading: "University of Georgia",
      subheading: "BS in Computer Science",
      daterange: {
        start: 2004,
        end: 2007
      }
    }
  ]
};

export const expertise = {
  heading: "Applicable Buzzwords",
  entries: [
    "React", "Redux",
    "Next.js", "TypeScript", "JavaScript",
    "Angular", "CI/CD",
    "HTML", "CSS",
    "REST APIs",
    "GraphQL", "Git",
    "Webpack", "Yarn/NPM",
    "Figma", "Bash Scripting",
    "MySQL", "PHP",
    "Unit Testing",
    "GSAP",
    "Reusable Components",
    "Lightning Web Components",
    "Project Architecture",
    "Framework Development",
    "Library Development"
  ]
}
