import styles from "./styles.module.css";

export const MainBodyEntry = ({
  heading,
  subheading,
  description,
  daterange: {
    start: startYear,
    end: endYear
  },
  first
}) => {
  const hasDescription = !!description;
  const descriptionParagraphs = hasDescription
    ? description.split("\n")
    : [];

  const dangerousHtml = {
    __html: subheading
  };

  return (
    <div className={styles.mainBodyEntry}>

      <div className={styles.daterange}>
        <span>{endYear}</span>
        <span>-</span>
        <span>{startYear}</span>
      </div>

      <div className={styles.body}>
        <span className={`${styles.timelineDot} ${first ? styles["--first"] : ""}`}/>
        <h3 className={styles.heading}>
          {heading}
        </h3>

        <div className={styles.subheading} dangerouslySetInnerHTML={dangerousHtml} />

        {hasDescription && (
          <div className={styles.description}>
            {descriptionParagraphs.map((p, index) => {
              return (
                <p key={`description-paragraph-${index}`}>{p}</p>
              );
            })}
          </div>
        )}
      </div>
    </div>
  )
};

export default MainBodyEntry;
