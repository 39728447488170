import Container from "../../components/container/container";
import MainBodySection from "../../components/main-body-section/main-body-section";
import Expertise from "../../components/expertise/expertise";
import SidebarEndcap from "../../components/sidebar-endcap/sidebar-endcap";

import { experienceP2 as experience } from "../../data/data";

import styles from "./styles.module.css";


export const P2 = () => {
  return (
    <Container
      sidebar={
        <>
          <Expertise />
          <SidebarEndcap />
        </>
      }
      body={
        <>
          <div className={styles.firstMainBodySection}>
            <MainBodySection {...experience} />
          </div>
        </>
      }

    />
  );
}

export default P2;
