import ProfilePic from "../../assets/images/profile-2.jpg"
// import ProfilePic from "../../assets/images/profile.png"

import styles from "./styles.module.css";

export const Headshot = () => {
  return (
    <div className={styles.headshot}>
      <img src={ProfilePic} alt="It's me, Jon" />
    </div>
  )
};

export default Headshot;
