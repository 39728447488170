import SidebarHeading from "../sidebar-heading/sidebar-heading";

import { profile } from "../../data/data";

import styles from "./styles.module.css";


export const Profile = () => {
  const paragraphs = profile.copy.split("\n");

  return (
    <div className={styles.profile}>
      <SidebarHeading>{profile.heading}</SidebarHeading>

      {paragraphs.map(p => {
        return <p>{p}</p>
      })}
    </div>
  );
}

export default Profile;
