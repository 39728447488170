import SidebarHeading from "../sidebar-heading/sidebar-heading";
import { ReactComponent as Envelope } from "../../assets/svgs/envelope.svg";
import { ReactComponent as MobileScreenSolid } from "../../assets/svgs/mobile-screen-solid.svg"

import { contact } from "../../data/data";

import styles from "./styles.module.css";


const iconMap = {
  envelope: Envelope,
  mobileScreenSolid: MobileScreenSolid
};

export const Contact = () => {
  return (
    <div className={styles.contact}>
      <SidebarHeading>{contact.heading}</SidebarHeading>
      <ul className={styles.contactMethods}>
        {contact.methods.map((method, index) => {
          const Icon = iconMap[method.icon] || false;

          return (
            <li key={`contact-method-${index}`}>
              {Icon && (
                <span className={styles.icon}>
                <Icon/>
              </span>
              )}
              <span className={styles.label}>
              {method.label}
            </span>
            </li>
          );
        })}
      </ul>
    </div>
  )
};

export default Contact;
