import { banner } from "../../data/data";

import styles from "./styles.module.css";

export const Banner = () => {
  return (
    <div className={styles.banner}>
      <h1 className={styles.headline}>{banner.heading}</h1>
      <div className={styles.subheading}>{banner.subheading}</div>
    </div>
  );
};

export default Banner;
