import Cover from "./pages/cover/cover";
import P2 from "./pages/p2/p2";
import P3 from "./pages/p3/p3";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Cover />
      <P2 />
      <P3 />
    </div>
  );
}

export default App;
