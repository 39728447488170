import Headshot from "../../components/headshot/headshot";
import Profile from "../../components/profile/profile";
import Contact from "../../components/contact/contact";
import Container from "../../components/container/container";
import Banner from "../../components/banner/banner";
import MainBodySection from "../../components/main-body-section/main-body-section";

import { experienceP1 as experience } from "../../data/data";

import styles from "./styles.module.css";


export const Cover = () => {
  return (
    <Container
      sidebar={
        <>
          <Headshot/>
          <Profile/>
          <Contact/>
        </>
      }
      body={
        <>
          <Banner />
          <div className={styles.firstMainBodySection}>
            <MainBodySection {...experience} />
          </div>
        </>
      }

    />
  );
}

export default Cover;
